import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SiteInfo from "../components/siteinformation"
import moment from "moment";

function dateOffset(site) {
    if (site == 2) {
        return ( 0 - 24*60*60*1000  - 60*1000 * 8.7);
    }
    if (site == 3) {
        return ( 0 - 29*60*60*1000  - 60*1000 * 2.4);
    }
    if (site == 4) {
        return  ( 0 - 12*60*60*1000  - 60*60*1000 * .58);
    }
    if (site == 5) {
        return  (0 - 6*60*60*1000  - 60*1000 * 22.7);
    }
    return 0;
}

function getPostDate(date) {
    var site = new SiteInfo().getSiteNumber();
    var newDate = new moment(Date.parse(date) + dateOffset(site));
    return newDate.format("YYYY/MM/DD HH:MM");
}

export default ({ data }) => {
  const post = data.allWordpressPost.edges[0].node

  return (
    <Layout>
      <div>
        <h1>{post.title}</h1>
          <h2>{getPostDate(post.date)}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressPost( filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date
        }
      }
    }
  }
`
